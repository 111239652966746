import {getSecondsFromTime} from "./raceResultService";

export interface RunnerLiteLap {
    Miles: number;
    ElapsedHours: number;
    LapSeconds: number;
    Lap: number;
}

export interface RunnerLite {
    RunnerName: string;
    RaceName: string;
    Bib: string;
    Laps?: RunnerLiteLap[]
}

export async function getRunnersFromUrl(url: string): Promise<RunnerLite[]> {
    const file = await fetch(url).then(response => response.text());

    return getRunnersFromFile(file);
}

export function getRunnersFromFile(file: string): RunnerLite[] {
    const lines = file.split(/\r?\n/);

    const runners: RunnerLite[] = [];
    let runner: RunnerLite|undefined;

    for (const line of lines) {
        const cells = line.split(',');

        const Miles = Number(cells[0]);
        const ElapsedHours = cells[1]?.includes(':') ?
            getSecondsFromTime(cells[1]) / 3600 :
            Number(cells[1]);

        if (cells[0].toLowerCase().trim() === 'runner') {
            const RunnerName = cells[1];
            const RaceName = cells[2];
            runner = {
                RunnerName: `${RaceName} ${RunnerName}`,
                RaceName,
                Bib: `${RaceName} ${RunnerName}`,
                Laps: [],
            };
            runners.push(runner);
        } else if (runner?.Laps && !isNaN(Miles) && !isNaN(ElapsedHours) && Miles > 0) {
            const PreviousHours = runner.Laps.length === 0 ? 0 : runner.Laps[runner.Laps.length - 1].ElapsedHours;
            const LapSeconds = (ElapsedHours - PreviousHours) * 60 * 60;
            runner.Laps.push({
                Miles,
                ElapsedHours,
                LapSeconds,
                Lap: runner.Laps.length + 1,
            })
        }
    }
    return runners;
}
