import React, {useEffect, useState} from 'react';
import {
    getSecondsFromTime,
    getTimeFromSeconds,
    RaceResultResponse,
    runnerComparator
} from "../services/raceResultService";

export interface PredictProps {
    runnerData?: RaceResultResponse;
}

export function Predict({runnerData}: PredictProps) {
    const [selectedBib, setSelectedBib] = useState("-1");
    const [goalMiles, setGoalMiles] = useState(172.46);

    const selectedRunner = runnerData?.runners?.find(x => x.Bib === selectedBib);

    useEffect(() => {
        (window as any).refresh = 30 * 1000;
    }, []);

    useEffect(() => {
        setSelectedBib(selectedRunner?.Bib ?? runnerData?.runners?.[0]?.Bib ?? "-1");
    }, [runnerData, selectedRunner?.Bib]);

    const selectOptions = runnerData?.runners
        .sort(runnerComparator)
        .map(runner => {
            return <option value={runner.Bib} key={runner.Bib}>
                {runner.RunnerName}
            </option>
        });

    const handleSelect = event => {
        setSelectedBib(event.target.value);
    };

    const handleGoalMiles = event => {
        setGoalMiles(Number(event.target.value));
    };

    const selectJsx = <select value={selectedRunner?.Bib} onChange={handleSelect}>{selectOptions}</select>;

    if (!selectedRunner) {
        return <div className="predict-wrapper">
            <h2>Predict</h2>
            {selectJsx}
        </div>;
    }

    const remainingMiles = goalMiles - Number(selectedRunner.TotalMiles);
    const remainingSeconds = (24 * 60 * 60) - getSecondsFromTime(selectedRunner.TotalTime);

    const laps = Math.ceil(remainingMiles * 1609.344 / 400);

    const lapPace = getTimeFromSeconds(remainingSeconds / remainingMiles);
    const pace = getTimeFromSeconds((remainingSeconds / remainingMiles) * (400 / 1609.344));

    return (
        <div className="predict-wrapper">
            <h2>Predict</h2>
            {selectJsx}
            <div>
                <span>Miles Target: </span>
                <input type="number" placeholder="Enter Mile Goal" value={goalMiles} onChange={handleGoalMiles}/>
            </div>
            <br/>
            <div>{selectedRunner.RunnerName}</div>
            <div>Lap Pace Required: <strong>{lapPace}</strong></div>
            <div>Pace Required: <strong>{pace}</strong></div>
            <div>Laps Required: <strong>{laps}</strong></div>
            <br/>
            <div>Current Miles: {selectedRunner.TotalMiles}</div>
            <div>Current Time: {selectedRunner.TotalTime}</div>
            <div>Current Laps: {selectedRunner.TotalLaps}</div>
            <div>Last Lap: {selectedRunner.LastLap}</div>
        </div>
    );
}