import React from 'react';
import {Gender, getTimeFromSeconds, RaceResultResponse, runnerComparator} from "../services/raceResultService";

export interface LeaderboardProps {
    runnerData?: RaceResultResponse;
}

const genderClassNameMap = {
    [Gender.M]: "male",
    [Gender.F]: "female",
}

export function Leaderboard({runnerData}: LeaderboardProps) {
    if (!runnerData) return null;

    const runnerRows = runnerData.runners
        .sort(runnerComparator)
        .map(runner => {
            const lastLap = getTimeFromSeconds(runner.Laps?.[runner.Laps.length - 1]?.LapSeconds);
            return <tr key={runner.Bib} className={genderClassNameMap[runner.Gender]}>
                <td>{runner.GenderPlace}</td>
                <td>{runner.RunnerName}</td>
                <td>{runner.TotalMiles}</td>
                <td>{runner.LastLap ?? lastLap}</td>
            </tr>
        });

    return (
        <div className="table-wrapper">
            <h2>Leaderboard</h2>
            <table>
                <thead>
                <tr>
                    <th>Place</th>
                    <th>Name</th>
                    <th>Miles</th>
                    <th>Last</th>
                </tr>
                </thead>
                <tbody>
                {runnerRows}
                </tbody>
            </table>
        </div>
    );
}