import React, {useEffect, useState} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {Leaderboard} from "./pages/Leaderboard";
import {downloadRaceResultData, RaceResultResponse} from "./services/raceResultService";
import {Main} from "./pages/Main";
import {Predict} from "./pages/Predict";
import {MomentumUpload} from './pages/MomentumUpload';
import {Charts} from "./pages/Charts";
import {RmtimingLive} from "./pages/RmtimingLive";

const urlParams = new URLSearchParams(window.location.search);
const qpRefresh: number = Number(urlParams.get('refresh'));
(window as any).refresh =  qpRefresh > 1000 ? qpRefresh : 10000;

export interface RaceConfig {
    type: string;
    resultsUrl: string;
    lapsUrl: string;
    courseLengthMeters: number;
    startTimeHoursOffset: number;
    name: string;
    date: string;
}

const RACE_RESULT = "raceresult";
const RM_TIMING = "rmtiming";

const config = {
    ds2022: {
        type: RACE_RESULT,
        resultsUrl: "https://my1.raceresult.com/228232/RRPublish/data/list?key=e7957b61e66691c3317fdbf8e87ab9e1&listname=Presenter%2FAnnouncer%7CLast%20Finishers&page=results&contest=0",
        lapsUrl: "https://my1.raceresult.com/228232/RRPublish/data/list?key=e7957b61e66691c3317fdbf8e87ab9e1&listname=Online%7CLap%20Details&page=results&contest=0",
        courseLengthMeters: 400,
        startTimeHoursOffset: 8,
        name: "Desert Solstice 2022",
        date: "2022-12-10"
    },
    jackpot2022: {
        type: RM_TIMING,
        name: "Jackpot 2022",
        date: "2022-02-18"
    },
    ds2021: {
        type: RACE_RESULT,
        resultsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=188236&key=4da8ab925f195455fdcc6ae1c7f300f3&listname=Presenter%2FAnnouncer%7CLast+Finishers",
        lapsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=188236&key=4da8ab925f195455fdcc6ae1c7f300f3&listname=Online%7CLap+Details",
        courseLengthMeters: 400,
        startTimeHoursOffset: 8,
        name: "Desert Solstice 2021",
        date: "2021-12-11"
    },
    dome2021: {
        type: RACE_RESULT,
        resultsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=172743&key=5113e0613677cfc6b70fd949fe12b2e0&listname=Result+Lists%7COverall+Results",
        lapsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=172743&key=5113e0613677cfc6b70fd949fe12b2e0&listname=Result+Lists%7CLap+Details",
        courseLengthMeters: 443.45522,
        startTimeHoursOffset: 0,
        name: "6 Days in the Dome 2021",
        date: "2021-06-18"
    },
    ds2020: {
        type: RACE_RESULT,
        resultsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=163027&key=7446c216a5fe4d094cdd39a287254802&listname=Presenter%2FAnnouncer%7CLast+Finishers",
        lapsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=163027&key=7446c216a5fe4d094cdd39a287254802&listname=Online%7CLap+Details",
        courseLengthMeters: 400,
        startTimeHoursOffset: 8,
        name: "Desert Solstice 2020",
        date: "2020-12-12"
    },
    aty2019: {
        type: RACE_RESULT,
        resultsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=145502&key=8b468d1031a8a2a5d4e4adef76238607&listname=Result+Lists%7CFinisher+List",
        lapsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=145502&key=8b468d1031a8a2a5d4e4adef76238607&listname=Result+Lists%7CLap+Details",
        courseLengthMeters: 1686.21,
        startTimeHoursOffset: 9,
        name: "ATY 2019-2020",
        date: "2019-12-28"
    },
    ds2019: {
        type: RACE_RESULT,
        resultsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=144084&key=68384c3caf10a62d408cfc2666140a25&listname=Result+Lists%7COverall+Results",
        lapsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=144084&key=68384c3caf10a62d408cfc2666140a25&listname=Result+Lists%7CLap+Details",
        courseLengthMeters: 400,
        startTimeHoursOffset: 8,
        name: "Desert Solstice 2019",
        date: "2019-12-14"
    },
    aty2018: {
        type: RACE_RESULT,
        eventId: "114748",
        key: "8666619641cd8ff8393312ded6a2cc33",
        resultsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=114748&key=8666619641cd8ff8393312ded6a2cc33&listname=Result+Lists%7CFinisher+List",
        lapsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=114748&key=8666619641cd8ff8393312ded6a2cc33&listname=Result+Lists%7CLap+Details",
        courseLengthMeters: 1686.21,
        startTimeHoursOffset: 9,
        name: "ATY 2018-2019",
        date: "2018-12-27"
    },
    aty2017: {
        type: RACE_RESULT,
        resultsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=88297&key=3e71f1b2125953ca4b77391b3736b101&listname=Result+Lists%7CFinisher+List",
        lapsUrl: "https://my.raceresult.com/RRPublish/data/list.php?eventid=88297&key=3e71f1b2125953ca4b77391b3736b101&listname=Result+Lists%7CLap+Details",
        courseLengthMeters: 1689.5,
        startTimeHoursOffset: 9,
        name: "ATY 2017-2018",
        date: "2017-12-27"
    }
};

declare const location: any;

// https://obs.aravaiparunning.com/ds2019/leaderboard
// http://localhost:3000/ds2019/leaderboard
const paramLocation = 3;

const raceParam = location.href.split("/")[paramLocation];
const raceConfig = config[raceParam];

function App() {
    const [runnerData, setRunnerData] = useState<RaceResultResponse>();

    useEffect(() => {
        if (raceConfig.type !== RACE_RESULT) return;

        downloadRaceResultData(raceConfig).then(data => setRunnerData(data));

        const updateData = () => {
            setTimeout(() => {
                downloadRaceResultData(raceConfig).then(data => setRunnerData(data));
                updateData();
            }, (window as any).refresh);
        };
    }, []);

    if (!raceConfig) return <h2>Race does not exist!</h2>;

    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/">
                        <Main/>
                    </Route>
                    <Route path="/:raceCode/leaderboard">
                        <Leaderboard runnerData={runnerData}/>
                    </Route>
                    <Route path="/:raceCode/predict">
                        <Predict runnerData={runnerData}/>
                    </Route>
                    <Route path="/:raceCode/momentum-upload">
                        <MomentumUpload runnerData={runnerData}/>
                    </Route>
                    <Route path="/:raceCode/charts">
                        <Charts runnerData={runnerData} raceConfig={raceConfig}/>
                    </Route>
                    <Route path="/:raceCode/rmtiming/live">
                        <RmtimingLive />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
