import {useEffect, useState} from "react";
import {
    RaceResultResponse
} from "../services/raceResultService";

export interface MomentumUploadProps {
    runnerData?: RaceResultResponse;
}

export function MomentumUpload({runnerData}: MomentumUploadProps) {
    const [lastUpdated, setLastUpdated] = useState("-");
  
    useEffect(() => {
        fetch("https://webhook.site/603cd19a-8887-494f-bdf3-f3f24b30fb2b", {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(runnerData)
          }).then(() => {
              setLastUpdated(new Date().toLocaleString());
          });
    }, [runnerData]);

    return (
        <div className="momentum-upload-wrapper">
            <h2>Momentum Upload</h2>
            <div>
                <span>Last Updated: {lastUpdated}</span>
            </div>
        </div>
    );
}