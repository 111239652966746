import React, {useEffect} from 'react';
import {Gender, getTimeFromSeconds, RaceResultResponse, runnerComparator} from "../services/raceResultService";

export interface RmtimingLiveProps {
}

const genderClassNameMap = {
    [Gender.M]: "male",
    [Gender.F]: "female",
}

export function RmtimingLive(props: RmtimingLiveProps) {
    useEffect(() => {
        console.log("Connecting");
        const socket = new WebSocket("wss://a7c93q29u0.execute-api.us-west-2.amazonaws.com/dev");
        console.log(socket);

        // Connection opened
        socket.addEventListener('open', function (event) {
            socket.send('Hello Server!');
            console.log('Hello Server!');
        });

        // Listen for messages
        socket.addEventListener('message', function (event) {
            console.log('Message from server ', event.data);
        });

    }, []);

    const runnerRows = [1,2,3,4,5]
        .map(index => {
            return <tr key={index} className={genderClassNameMap[Gender.M]}>
                <td>{index}</td>
                <td>{index}</td>
                <td>{index}</td>
                <td>{index}</td>
            </tr>
        });

    return (
        <div className="table-wrapper">
            <h2>Leaderboard</h2>
            <table>
                <thead>
                <tr>
                    <th>Place</th>
                    <th>Name</th>
                    <th>Miles</th>
                    <th>Last</th>
                </tr>
                </thead>
                <tbody>
                {runnerRows}
                </tbody>
            </table>
        </div>
    );
}