/* eslint-disable @typescript-eslint/no-unused-vars */

import axios from "axios";

export enum Gender {
    F = "F",
    M = "M",
}

export interface Runner {
    Bib: string;
    Place: string;
    RunnerName: string;
    RaceName: string;
    Age: string;
    Gender: Gender;
    GenderPlace: string;
    City: string;
    State: string;
    LastLap: string;
    TotalLaps: string;
    TotalMiles: string;
    TotalKM: string;
    TotalTime: string;
    Done: string;
    Laps?: Lap[];
    PredictedDistance: string;
}

export interface Lap {
    Lap: number;
    Miles: number;
    KM: number;
    ElapsedHours: number;
    LapSeconds: number;
}

export function downloadRaceResultData({resultsUrl, lapsUrl, courseLengthMeters, startTimeHoursOffset, name}): Promise<RaceResultResponse> {
    const raceName = name;
    const resultsPromise = axios.get(resultsUrl).then(response => response.data);
    const lapsPromise = axios.get(lapsUrl).then(response => response.data);

    return Promise.all([resultsPromise, lapsPromise])
        .then(([resultsResponse, lapsResponse]) => {

            const runnerRows = getRunnerRows(resultsResponse.data);

            const resultsColumns = extractColumns(resultsResponse.list.Fields);
            const runnerMap: { [bib: string]: Runner } = {};
            runnerRows.forEach(runnerRow => {

                const runner: any = getObjectFromRow(resultsColumns, runnerRow);

                const {Place, RunnerName, Age, City, State, Gender, LastLap, TotalLaps, TotalMiles, TotalKM, Bib, TotalTime, GenderPlace, Done, PredictedDistance} = runner;
                runnerMap[Bib] = {
                    Bib,
                    Place: Place && Place.split(".")[0],
                    RunnerName,
                    RaceName: runnerRow.raceName ?? raceName ?? "",
                    Age,
                    Gender,
                    GenderPlace,
                    City,
                    State,
                    LastLap,
                    TotalLaps,
                    TotalMiles,
                    TotalKM,
                    TotalTime,
                    Done,
                    Laps: [],
                    PredictedDistance,
                }
            });
            return [runnerMap, lapsResponse];
        })
        .then(([runnerMap, lapsResponse]) => {
            const runnerLaps = getLapRows(lapsResponse.data);

            runnerLaps.forEach(runnerLap => {
                const [bib, lapNumber, timeOfDayString, lapTimeString] = runnerLap;
                let elapsedHours;
                let lapSeconds;
                try {
                    const todResult = /(?:(\d+):)?(\d+):(\d+\.?\d*)/g.exec(timeOfDayString) as RegExpExecArray;
                    const lapResult = /(?:(\d+):)?(\d+):(\d+\.?\d*)/g.exec(lapTimeString) as RegExpExecArray;
                    const todHours = Number(todResult[1] || 0) - startTimeHoursOffset;
                    elapsedHours = (todHours * 3600 + Number(todResult[2]) * 60 + Number(todResult[3])) / 3600;
                    lapSeconds = Number(lapResult[1] || 0) * 3600 + Number(lapResult[2]) * 60 + Number(lapResult[3]);

                    const runner = runnerMap[bib];
                    const lap: Lap = {
                        Lap: lapNumber ? Number(lapNumber) : 0,
                        Miles: Math.round(lapNumber * 100 * courseLengthMeters / 1609.344) / 100,
                        KM: Math.round(lapNumber * 100 * courseLengthMeters / 1000) / 100,
                        ElapsedHours: elapsedHours,
                        LapSeconds: lapSeconds
                    };
                    runner?.Laps.push(lap);
                } catch (err) {
                }
            });

            const runners: Runner[] = Object.values(runnerMap);

            runners.sort(runnerComparator);
            runners.filter(x => x.Gender === Gender.M).forEach((x, i) => x.GenderPlace = x.GenderPlace ?? (i + 1) + x.Gender);
            runners.filter(x => x.Gender === Gender.F).forEach((x, i) => x.GenderPlace = x.GenderPlace ?? (i + 1) + x.Gender);

            runners.forEach(runner => runner?.Laps?.sort(lapComparator))
            return {
                runners
            };
        });
}

function getRunnerRows(resultsResponse: any) {
    if ("length" in resultsResponse) {
        return resultsResponse;
    }

    const allRunnerRows: any[] = [];
    const races = resultsResponse;
    Object.keys(races).forEach(raceKey => {
        const [number, raceName] = raceKey.split("_");
        const runnerRows = races[raceKey];
        runnerRows.forEach(x => x.raceName = raceName);
        allRunnerRows.push(...runnerRows)
    });

    return allRunnerRows;
}

function getLapRows(lapsResponse: any) {
    if (!lapsResponse) return [];
    if ("length" in lapsResponse) return lapsResponse;

    const allLapRows: any[] = [];
    Object.values(lapsResponse).forEach(x => allLapRows.push(...getLapRows(x)));
    return allLapRows;
}

const knownColumns = {
    "WithStatus([TotalRankp])": "Place",
    "WithStatus([OverallRank])": "Place",
    DisplayName: "RunnerName",
    AGE: "Age",
    CITY: "City",
    STATE2: "State",
    STATE: "State",
    SexMF: "Gender",
    GenderMF: "Gender",
    LastLap: "LastLap",
    NumberOfLaps: "TotalLaps",
    Miles: "TotalMiles",
    KM: "TotalKM",
    BIB: "Bib",
    "iif(T15=0;[Laps];[Laps]-1)": "TotalLaps",
    TIMETEXT: "TotalTime",
    TIME1: "TotalTime",
    "WithStatus([MFRankp])": "GenderPlace",
    "WithStatus([GenderRank])": "GenderPlace",
    "iif([CONTEST]=2;[PredDist12Hr];[PredDistance])": "PredictedDistance",
    "if([Done]=1;\"Done\";\" \")": "Done"
}

const knownLabels = {
    Time: "TotalTime",
    Laps: "TotalLaps",
    Done: "Done",
    Place: "Place",
    Age: "Age",
    Gender: "Gender",
    City: "City",
    State: "State",
    Country: "Country",
    Miles: "TotalMiles",
    KM: "TotalKM",
}

function extractColumns(fields) {
    const columns = {
        Bib: 0
    };
    fields.forEach((field, index) => {
        const offset = index + 1;
        const key = knownColumns[field.Expression] || knownLabels[field.Label];

        if (key) {
            columns[key] = offset;
        }
    });
    return columns;
}

function getObjectFromRow(columns, row) {
    const result = {};
    Object.keys(columns).forEach(columnKey => {
        const rowIndex = columns[columnKey];

        result[columnKey] = row[rowIndex];
    })
    return result;
}

export interface RaceResultResponse {
    runners: Runner[]
}

export function runnerComparator(a: Runner, b: Runner) {
    const aMiles = Number(a.TotalMiles);
    const bMiles = Number(b.TotalMiles);

    if (aMiles !== bMiles) return bMiles - aMiles;

    return getSeconds(b.TotalTime) - getSeconds(a.TotalTime);
}

export function lapComparator(a: Lap, b: Lap) {
    const aMiles = Number(a.Miles);
    const bMiles = Number(b.Miles);

    if (aMiles !== bMiles) return aMiles - bMiles;

    return b.ElapsedHours - a.ElapsedHours;
}

export function getSeconds(time) {
    const pieces = time.split(":").reverse();

    const seconds = pieces[0]
        + pieces[1] * 60
        + pieces[2] * 60 * 60
        + pieces[3] * 60 * 60 * 24;

    return seconds;
}

export function getSecondsFromTime(input: string): number {
    const [seconds, minutes, hours, days] = input.split(":").reverse();
    let result = days ? Number(days) : 0;
    result = (hours ? Number(hours) : 0 ) + (result * 24);
    result = (minutes ? Number(minutes) : 0 ) + (result * 60);
    result = (seconds ? Number(seconds) : 0 ) + (result * 60);
    return result;
}

export function getTimeFromSeconds(input): string {
    if (!input) return "";

    const totalSeconds = Math.floor(input);
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 24;

    let result = minutes + ":" + ("00" + seconds).slice(-2);
    if (hours > 0) {
        result = hours + ":" + ("00" + result).slice(-5);
    }

    return result;
}
