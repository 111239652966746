import React, {useEffect, useMemo} from 'react';
import {RaceResultResponse} from "../services/raceResultService";
import {RaceConfig} from "../App";
import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ScatterChart, Scatter, ReferenceLine
} from 'recharts';
import Chip from '@mui/material/Chip';
import {TextField} from "@mui/material";
import {getRunnersFromUrl, RunnerLite} from "../services/notablePerformanceService";
import {AxisDomain} from "recharts/types/util/types";

const RACE_HOURS = 24;

const colors: string[] = [
    "#D32F2F",
    "#536DFE",
    "#388E3C",
    "#FF9800",
    "#E040FB",
    "#5D4037",
    "#455A64",
    "#009688",
    "#0288D1",
    "#FF4081",
    "#9E9E9E",
    "#CDDC39",
];

const strokes = {
    dashed: '5 5',
    dotted: '2 2'
};

export interface ChartsProps {
    runnerData?: RaceResultResponse;
    raceConfig: RaceConfig;
}

const lines = [
    'Progress',
    'Pace',
    'Foresight',
    'Hindsight',
];

const notablePerformanceUrls = [
    'DS2018.csv',
    'DS2021.csv',
    'MMKat12.csv',
    'ASAlbi.csv',
    'CHAlbi.csv',
    'JJAlbi.csv',
    'OLAlbi.csv',
    'ASPab21WR.csv',
    'ATPab21.csv',
    'TFOslo21.csv',
    'PKDS15.csv',
    'YKAde97.csv',
];

const RenderNoShape = () => null;

interface ChartedRunnerLite extends RunnerLite {
    selected?: boolean;
}

let initialized = false;

export function Charts({runnerData, raceConfig}: ChartsProps) {
    const [optionsExpanded, setOptionsExpanded] = React.useState(true);
    const [selectedRunners, setSelectedRunners] = React.useState([] as string[]);
    const [selectedLines, setSelectedLines] = React.useState(new Set(['Progress']));
    const [baselineMiles, setBaselineMiles] = React.useState(0);
    const [series, setSeries] = React.useState([] as any);
    const [filter, setFilter] = React.useState('');
    const [notablePerformances, setNotablePerformances] = React.useState([] as ChartedRunnerLite[]);

    const allRunners: RunnerLite[] = useMemo(() => {
        const runners: RunnerLite[]
            = selectedRunners.map(bib => runnerData?.runners?.find(r => bib === r.Bib)).filter(x => x) as RunnerLite[];
        runners.push(...notablePerformances.filter(x => x.selected));
        return runners;
    }, [runnerData, notablePerformances, selectedRunners]);

    useEffect(() => {
        (window as any).refresh = 5 * 60 * 1000;
    }, []);

    useEffect(() => {
        (async function() {
            const performances: RunnerLite[] = [];
            for (const url of notablePerformanceUrls) {
                const runners = await getRunnersFromUrl(`/data/${url}`);
                performances.push(...runners);
            }
            setNotablePerformances(performances);
        })();
    }, []);

    useEffect(() => {
        if (!runnerData) return;
        setTimeout(() => {
            const series = mapRunnerDataToChartData(allRunners, selectedLines, raceConfig, baselineMiles);
            setSeries(series);
            if (runnerData && !initialized) {
                // Select the top runners
                initialized = true;
                const selected = runnerData.runners
                    .filter(x => !isNaN(Number(x.GenderPlace)) && Number(x.GenderPlace) >= 1 && Number(x.GenderPlace) <= 2)
                    .map(x => x.Bib);
                if (selected.length <= 4) {
                    setSelectedRunners(selected);
                }
            }
        }, 10);
    }, [runnerData, allRunners, selectedRunners, selectedLines, raceConfig, baselineMiles]);

    const runnerChipList = useMemo(() => {
        return runnerData?.runners
            .filter(runner => {
                const words = filter.split(' ').filter(x => x).map(x => x.toLowerCase());
                if (words.length === 0) return true;

                return words.every(x => {
                    return runner.RunnerName?.toLowerCase().includes(x)
                        || runner.Bib?.toLowerCase().includes(x)
                        || runner.RaceName?.toLowerCase().includes(x);
                });
            })
            .map(runner => {
            const onClick = () => {
                const index = selectedRunners.indexOf(runner.Bib);
                if (index > -1) {
                    setSelectedRunners(selectedRunners.filter(x => x !== runner.Bib));
                } else {
                    setSelectedRunners([...selectedRunners, runner.Bib]);
                }
            };

            return <div className="chip-wrapper" key={runner.Bib}>
                <Chip label={runner.RunnerName} variant={selectedRunners.indexOf(runner.Bib) > -1 ? 'filled' : 'outlined'} color="primary" onClick={onClick} />
            </div>;
        });
    }, [runnerData, filter, selectedRunners]);

    const chart = useMemo(() => {
        const yDomain = baselineMiles ?
            ['auto', 'auto'] as AxisDomain :
            [datamin => Math.floor(datamin/10) * 10, datamax => Math.ceil(datamax/10) * 10] as AxisDomain;

        return <ResponsiveContainer width="100%" height="100%">
            <ScatterChart width={500}
                          height={300}
                          margin={{
                              top: 20,
                              right: 20,
                              left: 20,
                              bottom: 5,
                          }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number"
                       dataKey="hours"
                       name="hours"
                       unit="H"
                       tickCount={24}
                       domain={[0, 24]}
                       allowDataOverflow={true}
                       allowDecimals={false}/>
                <YAxis type="number"
                       dataKey="distance"
                       name="distance"
                       unit="Miles"
                       domain={yDomain}
                       tickCount={15}
                       allowDecimals={true}/>
                <Tooltip />
                <Legend />
                {series.map((s) => (
                    <Scatter dataKey="hours"
                             data={s.data}
                             name={s.name}
                             key={s.name}
                             fill={s.color}
                             line
                             shape={<RenderNoShape />}
                             strokeDasharray={s.stroke ?? null}/>
                ))}
                {baselineMiles === 0 ? null : <ReferenceLine y={0} label={`${baselineMiles} Miles`} stroke="black" />}
            </ScatterChart>
        </ResponsiveContainer>;
    }, [series, baselineMiles]);

    if (!runnerData) return null;

    const lineChipList = lines.map(line => {
        const onClick = () => {
            if(selectedLines.has(line)) {
                selectedLines.delete(line);
            } else {
                selectedLines.add(line);
                if (line !== 'Progress') {
                    setBaselineMiles(0);
                }
            }
            setSelectedLines(new Set(selectedLines));
        };

        return <div className="chip-wrapper" key={line}>
            <Chip label={line} variant={selectedLines.has(line) ? 'filled' : 'outlined'} color="warning" onClick={onClick} />
        </div>;
    });

    const notablePerformanceChipList = notablePerformances.map(performance => {
        const onClick = () => {
            performance.selected = !performance.selected;
            setNotablePerformances([...notablePerformances]);
        };

        return <div className="chip-wrapper" key={performance.Bib}>
            <Chip label={performance.Bib} variant={performance.selected ? 'filled' : 'outlined'} color="primary" onClick={onClick} />
        </div>;
    });

    const onBaselineMilesChange = e => {
        setBaselineMiles(Number(e.target.value));
        selectedLines.clear();
        selectedLines.add('Progress');
        setSelectedLines(new Set(selectedLines));
    };

    const onFilterChange = e => {
        setFilter(e.target.value);
    };

    return <div>
        <div className="chart-page">
            <div className="chart-options-header"
                 onClick={() => setOptionsExpanded(!optionsExpanded)}>
                {optionsExpanded ? "Click to Collapse" : "Click to Set Options"}
            </div>
            <div style={{display: optionsExpanded ? '' : 'none'}} className='chart-options-panel'>
                <div className='chart-options-panel-column'>
                    <div>Runners</div>
                    <div style={{marginTop: '10px'}}>
                        <TextField id="filter"
                                   value={filter}
                                   onChange={onFilterChange}
                                   label="Search"
                                   variant="outlined"
                                   fullWidth
                                   inputProps={{}}/>
                    </div>
                    {runnerChipList}
                </div>
                <div className='chart-options-panel-column'>
                    <div>Lines</div>
                    {lineChipList}
                    <div style={{marginTop: '10px'}}>
                        <TextField id="baseline-miles"
                                   value={baselineMiles}
                                   onChange={onBaselineMilesChange}
                                   label="Baseline Miles"
                                   variant="outlined"
                                   type="number"
                                   fullWidth
                                   inputProps={{
                                       min: 0,
                                       max: 1000
                                   }}/>
                    </div>
                    <div>Notable Performances</div>
                    {notablePerformanceChipList}
                </div>
            </div>
            <div className="chart-container">
                {chart}
            </div>
        </div>
    </div>;
}

function mapRunnerDataToChartData(runners: RunnerLite[],
                                  selectedLines,
                                  raceConfig: RaceConfig,
                                  baselineMiles: number) {
    const series = runners.map((runner, index) => {
        if (!runner?.Laps?.length) return [];

        const series: any[] = [];
        const color = colors[index % colors.length];

        const offsetHours = Math.round(runner.Laps[0].ElapsedHours);
        const finalMiles = runner.Laps?.[runner.Laps?.length - 1].Miles;
        const finalHours = runner.Laps?.[runner.Laps?.length - 1].ElapsedHours - offsetHours;

        if (selectedLines.has('Progress')) {
            const progressSeries = {
                name: runner.RunnerName + " - Progress",
                color,
                data: runner.Laps?.map(lap => ({
                    hours: lap.ElapsedHours - offsetHours,
                    distance: lap.Miles - (baselineMiles * (lap.ElapsedHours - offsetHours) / RACE_HOURS),
                })) || []
            };
            series.push(progressSeries);
        }

        if (selectedLines.has('Pace')) {
            const paceSeries = {
                name: runner.RunnerName + " - Pace",
                color,
                data: runner.Laps.map((lap, index) => {
                    const previousMiles = index > 0 ? runner.Laps![index - 1].Miles : 0;
                    const lapLength = lap.Miles - previousMiles;
                    return {
                        hours: lap.ElapsedHours - offsetHours,
                        distance: (RACE_HOURS * 60 * 60 / lap.LapSeconds) * lapLength,
                    };
                }) || []
            };
            series.push(paceSeries);
        }

        if (selectedLines.has('Foresight')) {
            const foresightSeries = {
                name: runner.RunnerName + " - Foresight",
                color,
                stroke: strokes.dashed,
                data: runner.Laps?.map(lap => ({
                    hours: lap.ElapsedHours - offsetHours,
                    distance: lap.Miles * RACE_HOURS / (lap.ElapsedHours - offsetHours),
                })) || []
            };
            series.push(foresightSeries);
        }

        if (selectedLines.has('Hindsight')) {
            const hindsightSeries = {
                name: runner.RunnerName + " - Hindsight",
                color,
                stroke: strokes.dotted,
                data: runner.Laps?.map(lap => {
                    const denominator = (finalHours - lap.ElapsedHours + offsetHours);
                    return {
                        hours: lap.ElapsedHours - offsetHours,
                        distance: denominator <= 0 ? finalMiles : (finalMiles - lap.Miles) * RACE_HOURS / denominator,
                    };
                }) || []
            };
            series.push(hindsightSeries);
        }

        return series;
    }).flatMap(x => x);

    return series;
}
